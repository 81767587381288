import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Karte', url: '/map', icon: 'navigate' },
    { title: 'Bauvorhaben', url: '/construction-projects', icon: 'business' },
    //{ title: 'Dateien', url: '/files', icon: 'file-tray' },
    { title: 'Kunden', url: '/customers', icon: 'people' },
    { title: 'Angebote', url: '/offers', icon: 'list' },
    { title: 'Anfragen', url: '/requests', icon: 'mail-unread' },
    { title: 'Rechnungen', url: '/bills', icon: 'cash' },
    //{ title: 'Projekte', url: '/projects', icon: 'file-tray' },
    //{ title: 'Anlagen', url: '/constructions', icon: 'grid' },
    { title: 'Produkte', url: '/products', icon: 'basket' },
    { title: 'Services', url: '/services', icon: 'hammer' },
    { title: 'Daten', url: '/data', icon: 'albums' },
  ];
  public labels = ['Family'];


  public date = new Date().getTime();
  

  constructor() {}



}
