import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'testseite',
    loadChildren: () => import('./pages/testseite/testseite.module').then( m => m.TestseitePageModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'bills',
    loadChildren: () => import('./pages/bills/bills.module').then( m => m.BillsPageModule)
  },
  {
    path: 'domains',
    loadChildren: () => import('./pages/domains/domains.module').then( m => m.DomainsPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'product-create',
    loadChildren: () => import('./forms/product-create/product-create.module').then( m => m.ProductCreatePageModule)
  },
  {
    path: 'product-update',
    loadChildren: () => import('./forms/product-update/product-update.module').then( m => m.ProductUpdatePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'customer-create',
    loadChildren: () => import('./forms/customer-create/customer-create.module').then( m => m.CustomerCreatePageModule)
  },
  {
    path: 'customer-update',
    loadChildren: () => import('./forms/customer-update/customer-update.module').then( m => m.CustomerUpdatePageModule)
  },
  {
    path: 'files',
    loadChildren: () => import('./pages/files/files.module').then( m => m.FilesPageModule)
  },
  {
    path: 'file-upload',
    loadChildren: () => import('./forms/file-upload/file-upload.module').then( m => m.FileUploadPageModule)
  },
  {
    path: 'construction-projects',
    loadChildren: () => import('./pages/construction-projects/construction-projects.module').then( m => m.ConstructionProjectsPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then( m => m.ProjectsPageModule)
  },
  {
    path: 'constructions',
    loadChildren: () => import('./pages/constructions/constructions.module').then( m => m.ConstructionsPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'data',
    loadChildren: () => import('./pages/data/data.module').then( m => m.DataPageModule)
  },
  {
    path: 'construction-project-update',
    loadChildren: () => import('./forms/construction-project-update/construction-project-update.module').then( m => m.ConstructionProjectUpdatePageModule)
  },
  {
    path: 'note-create',
    loadChildren: () => import('./forms/note-create/note-create.module').then( m => m.NoteCreatePageModule)
  },
  {
    path: 'construction-create',
    loadChildren: () => import('./forms/construction-create/construction-create.module').then( m => m.ConstructionCreatePageModule)
  },
  {
    path: 'customer-import',
    loadChildren: () => import('./forms/customer-import/customer-import.module').then( m => m.CustomerImportPageModule)
  },
  {
    path: 'offer-create',
    loadChildren: () => import('./forms/offer-create/offer-create.module').then( m => m.OfferCreatePageModule)
  },
  {
    path: 'project-create',
    loadChildren: () => import('./forms/project-create/project-create.module').then( m => m.ProjectCreatePageModule)
  },  {
    path: 'requests',
    loadChildren: () => import('./pages/requests/requests.module').then( m => m.RequestsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
